import './contact.css';
import './../../styles/global.css';
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Thankyou from '../../components/Thankyou/Thankyou';

function Contact() {
  const form = useRef();
  const [isSent, setIsSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_1mzgi87', 'template_omcrzh1', form.current, {
        publicKey: '1PlCIeHuBAKmUcva5',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setIsSent(true);
        },
        (error) => {
          alert('FAILED...', error.text);
        },
      );
  };

  return (
    <div className="container position-relative">
      <Header />
      {isSent ? (
        <Thankyou />
      ) : (
        <div className="contact">
          <h2>We will be glad to contact you</h2>
          <p>Give us your email and we will send a message you now</p>
          <form ref={form} onSubmit={sendEmail}>
            <div className="form-input">
              <input
                type="text"
                name="user_name"
                placeholder="Your Name"
                required
              />
            </div>
            <div className="form-input">
              <input
                type="email"
                name="user_email"
                placeholder="Your Email:"
                required
              />
            </div>
            <input type="submit" value="Contact us" className="custom-button" />
          </form>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default Contact;
