import React from 'react';
import { useInView } from 'react-intersection-observer';
import './fadeinsection.css';

const FadeInSection = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.1, // Adjust this value to determine when the animation triggers
  });

  return (
    <div ref={ref} className={`fade-in-section ${inView ? 'is-visible' : ''}`}>
      {children}
    </div>
  );
};

export default FadeInSection;
