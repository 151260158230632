// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fade-in-section {
  opacity: 0;
  transform: translateY(20px);
  transition:
    opacity 0.6s ease-out,
    transform 0.6s ease-out;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: translateY(0);
} `, "",{"version":3,"sources":["webpack://./src/components/FadeInSection/fadeinsection.scss","webpack://./src/components/FadeInSection/fadeinsection.css"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,2BAAA;EACA;;2BCEyB;ADG3B;;ACAA;EACA,UAAA;EACE,wBAAwB;AAC1B","sourcesContent":[".fade-in-section {\n  opacity: 0;\n  transform: translateY(20px);\n  transition:\n    opacity 0.6s ease-out,\n    transform 0.6s ease-out;\n}\n\n.fade-in-section.is-visible {\n  opacity: 1;\n  transform: translateY(0);\n}\n",".fade-in-section {\n  opacity: 0;\n  transform: translateY(20px);\n  transition:\n    opacity 0.6s ease-out,\n    transform 0.6s ease-out;\n}\n\n.fade-in-section.is-visible {\n  opacity: 1;\n  transform: translateY(0);\n} /*# sourceMappingURL=fadeinsection.css.map */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
