// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  color: #ffffff;
  border: none;
}

.process {
  padding: 20px;
  max-width: 1250px;
  margin: 0 auto;
} `, "",{"version":3,"sources":["webpack://./src/components/Process/process.scss","webpack://./src/components/Process/process.css"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,UAAA;EACA,cAAA;EACA,YAAA;ACCF;;ADEA;EACE,aAAA;EACA,iBAAA;EACA,cAAA;ACCF","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  color: #ffffff;\n  border: none;\n}\n\n.process {\n  padding: 20px;\n  max-width: 1250px;\n  margin: 0 auto;\n}\n","* {\n  margin: 0;\n  padding: 0;\n  color: #ffffff;\n  border: none;\n}\n\n.process {\n  padding: 20px;\n  max-width: 1250px;\n  margin: 0 auto;\n} /*# sourceMappingURL=process.css.map */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
