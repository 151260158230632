import React, { useState } from 'react';
import './yerevan.css';

const Yerevan = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleMouseOver = () => {
    setIsVisible(true);
  };

  const handleMouseOut = () => {
    setIsVisible(false);
  };

  return (
    <>
      <div
        className="yvn"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      ></div>
      <div className="contact-item">
        <div className={`content ${isVisible ? 'd-flex' : 'd-none'}`}>
          <div className="item">
            <p>Armenia, Hajn</p>
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/images/bottom.svg`}
            alt="arrow bottom"
            className="arrow-down"
          />
        </div>
      </div>
    </>
  );
};

export default Yerevan;
