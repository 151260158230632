// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location {
  padding-top: 30px;
  position: relative;
}
.location img {
  width: 100%;
}
.location-item {
  background-color: #ffffff;
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  margin: 5px;
  width: 47%;
  margin-bottom: 20px;
}
.location-item p {
  color: #667085;
  padding-right: 0;
}
.location-item p:first-child {
  color: #344054;
}
.location-item a {
  display: block;
  color: #0073b9;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}
.location-mobile {
  flex-wrap: wrap;
} `, "",{"version":3,"sources":["webpack://./src/components/Location/location.scss","webpack://./src/components/Location/location.css"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;ACCF;ADCE;EACE,WAAA;ACCJ;ADEE;EACE,yBAAA;EACA,4BAAA;EACA,kBAAA;EACA,WAAA;EACA,UAAA;EACA,mBAAA;ACAJ;ADEI;EACE,cAAA;ECAN,gBAAA;ADEM;ACAN;EACA,cAAA;ADII;ACFJ;EDIM,cAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;ECFN,gBAAA;ADKE;ACHF;EACA,eAAA;AACA","sourcesContent":[".location {\n  padding-top: 30px;\n  position: relative;\n\n  img {\n    width: 100%;\n  }\n\n  &-item {\n    background-color: #ffffff;\n    padding: 12px 16px 12px 16px;\n    border-radius: 8px;\n    margin: 5px;\n    width: 47%;\n    margin-bottom: 20px;\n\n    p {\n      color: #667085;\n\n      &:first-child {\n        color: #344054;\n      }\n    }\n\n    a {\n      display: block;\n      color: #0073b9;\n      font-size: 12px;\n      line-height: 14px;\n      font-weight: 400;\n    }\n  }\n  &-mobile {\n    flex-wrap: wrap;\n  }\n}\n",".location {\n  padding-top: 30px;\n  position: relative;\n}\n.location img {\n  width: 100%;\n}\n.location-item {\n  background-color: #ffffff;\n  padding: 12px 16px 12px 16px;\n  border-radius: 8px;\n  margin: 5px;\n  width: 47%;\n  margin-bottom: 20px;\n}\n.location-item p {\n  color: #667085;\n  padding-right: 0;\n}\n.location-item p:first-child {\n  color: #344054;\n}\n.location-item a {\n  display: block;\n  color: #0073b9;\n  font-size: 12px;\n  line-height: 14px;\n  font-weight: 400;\n}\n.location-mobile {\n  flex-wrap: wrap;\n} /*# sourceMappingURL=location.css.map */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
