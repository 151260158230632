import React, { useState, useRef, useEffect } from 'react';
import Process from '../../components/Process/Process';
import Headline from '../../components/Headline/Headline';
import Product from '../../components/Product/Product';
import Career from '../../components/Career/Career';
import Location from '../../components/Location/Location';
import Banner from '../../components/Banner/Banner';
import Gallery from '../../components/Gallery/Gallery';
import './../../styles/global.css';
import About from '../../components/About/About';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Button from '../../components/Button/Button';
import Heading from '../../components/Headline/Headline';
import FadeInSection from '../../components/FadeInSection/FadeInSection';

function Home() {
  const productRef = useRef(null);
  const [isProductVisible, setIsProductVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsProductVisible(true);
          } else {
            setIsProductVisible(false);
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.2, 
      },
    );

    if (productRef.current) {
      observer.observe(productRef.current);
    }

    return () => {
      if (productRef.current) {
        observer.unobserve(productRef.current);
      }
    };
  }, []);

  return (
    <div className="home position-relative" name="/">
      <Header />
      <div className="container">
        <FadeInSection>
          <Banner />
        </FadeInSection>
        <div ref={productRef}>
          <FadeInSection>
            <About isProductVisible={isProductVisible} />
          </FadeInSection>
          <Headline title="Expertise" />
          <FadeInSection>
            <Process />
          </FadeInSection>
        </div>
        <FadeInSection>
          <Product />
        </FadeInSection>
        {/* <Headline title="Career" />
        <FadeInSection>
          <Career />
        </FadeInSection> */}
        <Headline title="Locations" />
        <FadeInSection>
          <Location />
        </FadeInSection>
        <Headline title="Cooperation" />
      </div>
      <FadeInSection>
        <Gallery />
      </FadeInSection>
      <div className="container" style={{ marginTop: '60px' }}>
        <Heading
          title="Do you want to create a Diamond?"
          attr={<Button text="Contact us" url="/contact" />}
        />
        <Footer />
      </div>
    </div>
  );
}

export default Home;
