// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-button {
  color: #171c1f;
  margin: 16px 0;
}
.custom-button a {
  background: #f8f8f8;
  color: #171c1f;
  padding: 12px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  width: 90%;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  font-weight: 600;
}
.custom-button img {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 40px;
  height: 40px;
}
@media (min-width: 768px) {
  .custom-button a {
    display: inline;
    padding: 12px 4px 16px 32px;
  }
  .custom-button img {
    margin-left: 12px;
    position: unset;
  }
} `, "",{"version":3,"sources":["webpack://./src/components/Button/button.scss","webpack://./src/components/Button/button.css"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,cAAA;ACCF;ADCE;EACE,mBAAA;EACA,cAAA;EACA,aAAA;EACA,qBAAA;EACA,eAAA;EACA,aAAA;EACA,UAAA;EACA,uBAAA;EACA,kBAAA;EACA,cAAA;EACA,gBAAA;ACCJ;ADEE;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;ACAJ;ADGE;EACE;IACE,eAAA;IACA,2BAAA;ECDJ;EDIE;IACE,iBAAA;IACA,eAAA;ECFJ;AACF","sourcesContent":[".custom-button {\n  color: #171c1f;\n  margin: 16px 0;\n\n  a {\n    background: #f8f8f8;\n    color: #171c1f;\n    padding: 12px;\n    text-decoration: none;\n    cursor: pointer;\n    display: flex;\n    width: 90%;\n    justify-content: center;\n    position: relative;\n    margin: 0 auto;\n    font-weight: 600;\n  }\n\n  img {\n    position: absolute;\n    top: 4px;\n    right: 4px;\n    width: 40px;\n    height: 40px;\n  }\n\n  @media (min-width: 768px) {\n    a {\n      display: inline;\n      padding: 12px 4px 16px 32px;\n    }\n\n    img {\n      margin-left: 12px;\n      position: unset;\n    }\n  }\n}\n",".custom-button {\n  color: #171c1f;\n  margin: 16px 0;\n}\n.custom-button a {\n  background: #f8f8f8;\n  color: #171c1f;\n  padding: 12px;\n  text-decoration: none;\n  cursor: pointer;\n  display: flex;\n  width: 90%;\n  justify-content: center;\n  position: relative;\n  margin: 0 auto;\n  font-weight: 600;\n}\n.custom-button img {\n  position: absolute;\n  top: 4px;\n  right: 4px;\n  width: 40px;\n  height: 40px;\n}\n@media (min-width: 768px) {\n  .custom-button a {\n    display: inline;\n    padding: 12px 4px 16px 32px;\n  }\n  .custom-button img {\n    margin-left: 12px;\n    position: unset;\n  }\n} /*# sourceMappingURL=button.css.map */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
