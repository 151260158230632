import China from './China/China';
import Dubai from './Dubai/Dubai';
import India from './India/India';
import './location.css';
import Yerevan from './Yerevan/Yerevan';

function Location() {
  return (
    <div className="location" name="locations">
      <img
        className="d-md-block d-none"
        src={`${process.env.PUBLIC_URL}/images/location.png`}
        alt="image"
      />
      <div className="d-md-block d-none">
        <Yerevan />
        <Dubai />
        <India />
        <China />
      </div>
      <div className="location-mobile d-md-none d-flex">
        <div className="location-item">
          <p>Armenia, Hajn</p>
        </div>
        <div className="location-item">
          <p>Dubai, UAE</p>
        </div>
        <div className="location-item">
          <p>India, Mumbai</p>
        </div>
        <div className="location-item">
          <p>Hong Kong, China</p>
        </div>
      </div>
    </div>
  );
}

export default Location;
