// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper {
  max-width: 580px;
}
.swiper img {
  width: 100%;
}

.swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0 !important;
  padding-top: 12px !important;
  position: relative !important;
}

.swiper-pagination-bullet {
  background: #ffffff !important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 10px !important;
  height: 10px !important;
}

.slide-text {
  position: absolute;
  left: 0;
  bottom: 20px;
  background-color: transparent;
  padding: 20px 30px;
}
.slide-text p {
  text-align: left;
  margin-bottom: 6px;
  background-color: transparent;
} `, "",{"version":3,"sources":["webpack://./src/Swiper/swiper.scss","webpack://./src/Swiper/swiper.css"],"names":[],"mappings":"AAAA;EACE,gBAAA;ACCF;ADAE;EACE,WAAA;ACEJ;;ADEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,oBAAA;EACA,4BAAA;EACA,6BAAA;ACCF;;ADCA;EACE,8BAAA;ACEF;;ADCA;EACE,sBAAA;EACA,uBAAA;ACEF;;ADAA;EACE,kBAAA;EACA,OAAA;EACA,YAAA;EACA,6BAAA;EACA,kBAAA;ACGF;ADFE;EACE,gBAAA;EACA,kBAAA;EACA,6BAAA;ACIJ","sourcesContent":[".swiper {\n  max-width: 580px;\n  img {\n    width: 100%;\n  }\n}\n\n.swiper-pagination {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  bottom: 0 !important;\n  padding-top: 12px !important;\n  position: relative !important;\n}\n.swiper-pagination-bullet {\n  background: #ffffff !important;\n}\n\n.swiper-pagination-bullet.swiper-pagination-bullet-active {\n  width: 10px !important;\n  height: 10px !important;\n}\n.slide-text {\n  position: absolute;\n  left: 0;\n  bottom: 20px;\n  background-color: transparent;\n  padding: 20px 30px;\n  p {\n    text-align: left;\n    margin-bottom: 6px;\n    background-color: transparent;\n  }\n}\n",".swiper {\n  max-width: 580px;\n}\n.swiper img {\n  width: 100%;\n}\n\n.swiper-pagination {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  bottom: 0 !important;\n  padding-top: 12px !important;\n  position: relative !important;\n}\n\n.swiper-pagination-bullet {\n  background: #ffffff !important;\n}\n\n.swiper-pagination-bullet.swiper-pagination-bullet-active {\n  width: 10px !important;\n  height: 10px !important;\n}\n\n.slide-text {\n  position: absolute;\n  left: 0;\n  bottom: 20px;\n  background-color: transparent;\n  padding: 20px 30px;\n}\n.slide-text p {\n  text-align: left;\n  margin-bottom: 6px;\n  background-color: transparent;\n} /*# sourceMappingURL=swiper.css.map */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
