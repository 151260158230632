// Import Swiper React components
import { Pagination, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import './gallery.css';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

export default () => {
  return (
    <Swiper
      slidesPerView={4}
      spaceBetween={30}
      freeMode={true}
      loop={true}
      autoplay={true}
      pagination={{
        clickable: true,
      }}
      className="gallery"
      modules={[FreeMode, Pagination]}
      name="cooperation"
    >
      <SwiperSlide>
        <a href="https://ydi-d.co.il" target="_blank">
          <div className="position-relative">
            <img
              src={`${process.env.PUBLIC_URL}/images/card.png`}
              alt="Slide 1"
            />
            <div className="logo" style={{ top: '40%' }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/ydi-diamonds.svg`}
                alt="YDI Diamonds"
              />
            </div>
            <p className="text">YDI Diamonds</p>
          </div>
        </a>
      </SwiperSlide>
      <SwiperSlide>
        <a href="https://laxmidiamond.com" target="_blank">
          <div className="position-relative">
            <img
              src={`${process.env.PUBLIC_URL}/images/card.png`}
              alt="Slide 1"
            />
            <div className="logo" style={{ top: '30%' }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/laxmi-diamond.svg`}
                alt="Laxmi Diamond"
              />
            </div>
            <p className="text">Laxmi Diamond</p>
          </div>
        </a>
      </SwiperSlide>
      <SwiperSlide>
        <a href="https://gemelodyjewelry.com/pages/contact" target="_blank">
          <div className="position-relative">
            <img
              src={`${process.env.PUBLIC_URL}/images/card.png`}
              alt="Gemelody"
            />
            <div className="logo" style={{ top: '40%' }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/gemelody.webp`}
                alt="Gemelody"
              />
            </div>
            <p className="text">Gemelody</p>
          </div>
        </a>
      </SwiperSlide>
      <SwiperSlide>
        <a href="https://www.royalraysbv.com" target="_blank">
          <div className="position-relative">
            <img
              src={`${process.env.PUBLIC_URL}/images/card.png`}
              alt="Gemelody"
            />
            <div className="logo" style={{ top: '30%' }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/royalrays.png`}
                alt="Royal Rays"
              />
            </div>
            <p className="text">Royal Rays</p>
          </div>
        </a>
      </SwiperSlide>
      <SwiperSlide>
        <a href="https://omusallc.com/portfolio/om-rose/" target="_blank">
          <div className="position-relative">
            <img
              src={`${process.env.PUBLIC_URL}/images/card.png`}
              alt="Gemelody"
            />
            <div
              className="logo"
              style={{ top: '30%', width: '300px', left: '10%' }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/omusa.png`}
                alt="OmRose LLC"
              />
            </div>
            <p className="text">OmRose LLC</p>
          </div>
        </a>
      </SwiperSlide>
    </Swiper>
  );
};
