import './../../pages/Contact/contact.css';
import Button from '../Button/Button';

import React from 'react';

function Thankyou() {
  return (
    <div className="container">
      <div className="contact thankyou">
        <h2>Thank you!</h2>
        <p>We will contact with you ASAP</p>
        <Button text="Go To Homepage" url="/" />
      </div>
    </div>
  );
}

export default Thankyou;
