// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner {
  padding: 20px;
  max-width: 1250px;
  margin: 60px 0 auto;
}
.banner h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  color: #f8f8f8;
}
.banner h6 {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #f8f8f8;
  margin: 0;
}
.banner img {
  width: 302px;
  height: 354px;
}
@media (min-width: 992px) {
  .banner {
    margin: 120px 0 auto;
  }
  .banner h1 {
    font-size: 104px;
    line-height: 116px;
  }
  .banner img {
    width: auto;
    height: auto;
  }
} `, "",{"version":3,"sources":["webpack://./src/components/Banner/banner.scss","webpack://./src/components/Banner/banner.css"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;EACA,mBAAA;ACCF;ADCE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;ACCJ;ADEE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,SAAA;ACAJ;ADGE;EACE,YAAA;EACA,aAAA;ACDJ;ADGE;EAxBF;IAyBI,oBAAA;ECAF;EDEE;IACE,gBAAA;IACA,kBAAA;ECAJ;EDGE;IACE,WAAA;IACA,YAAA;ECDJ;AACF","sourcesContent":[".banner {\n  padding: 20px;\n  max-width: 1250px;\n  margin: 60px 0 auto;\n\n  h1 {\n    font-size: 48px;\n    font-weight: 700;\n    line-height: 60px;\n    color: #f8f8f8;\n  }\n\n  h6 {\n    font-size: 18px;\n    font-weight: 500;\n    line-height: 22px;\n    color: #f8f8f8;\n    margin: 0;\n  }\n\n  img {\n    width: 302px;\n    height: 354px;\n  }\n  @media (min-width: 992px) {\n    margin: 120px 0 auto;\n\n    h1 {\n      font-size: 104px;\n      line-height: 116px;\n    }\n\n    img {\n      width: auto;\n      height: auto;\n    }\n  }\n}\n",".banner {\n  padding: 20px;\n  max-width: 1250px;\n  margin: 60px 0 auto;\n}\n.banner h1 {\n  font-size: 48px;\n  font-weight: 700;\n  line-height: 60px;\n  color: #f8f8f8;\n}\n.banner h6 {\n  font-size: 18px;\n  font-weight: 500;\n  line-height: 22px;\n  color: #f8f8f8;\n  margin: 0;\n}\n.banner img {\n  width: 302px;\n  height: 354px;\n}\n@media (min-width: 992px) {\n  .banner {\n    margin: 120px 0 auto;\n  }\n  .banner h1 {\n    font-size: 104px;\n    line-height: 116px;\n  }\n  .banner img {\n    width: auto;\n    height: auto;\n  }\n} /*# sourceMappingURL=banner.css.map */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
